import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import "../css/blog.css";

function TermsTemplate({ pageContext }) {
  const term = pageContext;

  // const { title, author, date } = term.frontmatter;

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marketing Terms | {term.title}</title>
        <script
          type="text/javascript"
          async=""
          src="https://www.google-analytics.com/analytics.js"
        ></script>
        <script
          async=""
          src="//www.googletagmanager.com/gtm.js?id=GTM-M346X68"
        ></script>
        <meta name="description" content={term.description} />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="Terms term"></meta>
        <meta property="og:title" content={term.title}></meta>

        <meta property="article:published_time" content={term.date}></meta>
        <meta property="og:description" content={term.description}></meta>
        <meta
          property="og:site_name"
          content="Consult FGC | Digital Marketing &amp; SEO Company"
        ></meta>
        <meta name="twitter:card" content={term.image}></meta>
        <meta name="twitter:description" content={term.description}></meta>
        <meta
          name="twitter:title"
          content={"Marketing Terms | " + term.title}
        ></meta>
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>
      <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div class="mb-8 text-center ">
          <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-700 sm:text-5xl sm:leading-none md:text-6xl">
            {term.title}
          </h2>
        </div>

        <div className="w-full">
          <div className="text-center px-4 text-base lg:px-0 mt-12 text-gray-500 text-lg leading-relaxed w-full ">
            <div
              className="termStyling text-left pb-6"
              dangerouslySetInnerHTML={{ __html: term.content }}
            />
          </div>
          <div className="text-center pt-5 pb-10 md:inline lg:block ">
            <Link to="/marketing-terms/">
              <button className="bg-blue-600  rounded-xl text-white tracking-wider inline-flex items-center px-16 py-4 border border-transparent text-sm leading-4 hover:bg-blue-500 transition ease-in-out duration-500 ">
                Back to Marketing Terms
              </button>
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default TermsTemplate;

// <div className="pb-6" dangerouslySetInnerHTML={{ __html: term.content }} />//
